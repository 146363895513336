import moment from 'moment';
import jsonLogic from 'json-logic-js';
import config from './uiConfig';

export const formatDateInterval = (startDateU, endDateU) => {
  const startDate = moment(startDateU);
  const endDate = moment(endDateU);
  if (!endDateU || endDateU === startDateU)
    return startDate.format("LL");
  if (startDate.month() === endDate.month())
    return `${startDate.format("MMMM D")} – ${endDate.format("D, YYYY")}`;
  return moment(startDate).format("MMMM D") + " – " + moment(endDate).format("LL");
}

const setValidity = (id, text) => {
  const el = document.getElementById(id);
  el.setCustomValidity(text);
  el.reportValidity();
}

export const validateCustomFields = (formMetaData, state, setErrors) => {
  if (!formMetaData)
    return true;
  for (let field of formMetaData.fields) {
    if (field.condition && !jsonLogic.apply(field.condition, state))
      continue;
    if (field.type === "text" && field.regex && state[field.id]) {
      const re = new RegExp(field.regex);
      if (!re.test(state[field.id])) {
        console.warn("validation error", field.id, field.regex, state[field.id]);
        setValidity(field.id, "Wrong format");
        return false;
      }
    }
    if (field.type === "phone" && field.mandatory) {
      if (state[field.id].length < 8) {
        console.warn("validation error", field.id);
        setValidity(field.id, field.label + " required");
        return false;
      }
    }
    if (field.type === "radio" && field.mandatory) {
      if (!state[field.id]) {
        console.warn("validation error", field.id);
        setValidity(field.id + "0", "Please choose an option");
        return false;
      }
    }
    if (field.type === "checkbox" && field.mandatory) {
      if (!state[field.id]) {
        console.warn("validation error", field.id);
        setValidity(field.id, "You must check to proceed");
        return false;
      }
    }
    if (field.type === "checkbox" && field.mandatory) {
      if (!state[field.id]) {
        console.warn("validation error", field.id);
        setValidity(field.id, "You must check to proceed");
        return false;
      }
    }
    if (field.type === "select" && field.mandatory) {
      if (!state[field.id]) {
        console.warn("validation error", field.id);
        setErrors({ [field.id]: "Please choose an option" })
        // setValidity(field.id, "Please choose an option");
        return false;
      }
    }
  }
  return true;
}

export const getPricingData = (reasons, state) => {
  let pricing = {};
  for (let reason of reasons) {
    pricing[reason] = state[reason];
  }
  return JSON.stringify(pricing);
}

export const getUpcomingEvents = events => events.filter(x => moment.duration(moment() - moment(x.endDate || x.startDate)).asDays() < (config.eventsDebugMode ? 9999 : 2))