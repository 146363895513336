/* eslint-disable */
const config = {
  // apiHost: "https://slidespielportalsapi.azurewebsites.net",
  apiHost: "https://slidespielportalsapitest.azurewebsites.net",
  spmApiHost: "https://slidespielspeakersapi.azurewebsites.net",
  chatRealm: "https://chat.slidespiel.com",
  stripeKey: "pk_test_0hlApLH5MBAWQzgK4nfccP1H",
  url: "",
  env: "development",
  portalName: "comtech-ce",
  title: "ConVEx Tempe",
  questionsBeforeRecording: false,
  portals: [
    { year: 2020, id: "024221df-b8b0-4938-b2ee-13977a7605ad" },
  ],
  defaultYear: 2020,
  // amplitudeKey: "5a691f5d28c551997691879d728c1da7",
  version: "1.0.1",
};

if (process.env.NODE_ENV === "production") {
  Object.assign(config, {
    apiHost: "https://slidespielportalsapi.azurewebsites.net",
    stripeKey: "pk_live_nJvbydZp1mBjTIkW4mzW33B5",
    env: "production",
    sentryDsn: "https://66ebec47e0014dccb50af5e51f5348eb@o361175.ingest.sentry.io/5264442",
  });
}


export default config;
