export const portals = [
  {
    year: 2020,
    home: {
      mainText: "Access Education from The Top Specialists!",
      secondaryText: "Test",
      list: [
        "Virtual Exhibit Hall",
        "24-hours access to the educational content",
        "Available on mobile devices"
      ],
      ctaText: "Sign In",
      ctaTextLoggedIn: "On-demand",
    }
  },
]
export const tiers = [
  {
    id: 0,
    title: 'Session',
    price: '60',
    memberPrice: '60',
    description: ['Individual session'],
    buttonText: 'Select',
    buttonVariant: 'outlined',
    summary: "Individual Session"
  },
  // {
  //   id: 1,
  //   title: 'Track',
  //   subheader: 'Most popular',
  //   price: '250',
  //   memberPrice: '195',
  //   description: [
  //     'Four sessions',
  //     'Keynote presentation',
  //   ],
  //   buttonText: 'Select',
  //   buttonVariant: 'contained',
  //   summary: "Track (4 sessions)"
  // },
  // {
  //   id: 2,
  //   title: 'Conference',
  //   price: '450',
  //   memberPrice: '395',
  //   description: [
  //     'All sessions and keynote presentation',
  //   ],
  //   buttonText: 'Select',
  //   buttonVariant: 'outlined',
  //   summary: "Entire Conference"
  // },
];
