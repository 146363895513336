import React, { useState, useEffect, useCallback } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import ListItemText from '@material-ui/core/ListItemText';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline';
import api from "../../api";
import { inject } from "mobx-react";
import { useFeedback } from '../feedback/Service';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const UserEventsDialog = ({ userId, open, handleClose, allEvents }) => {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(0);
  const [sendEventEmail, setSendEventEmail] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const feedback = useFeedback();

  const getUserEvents = useCallback(() => {
    if (!userId) return;
    setIsFetching(true);
    api.getUserEvents(userId).then(events => {
      setEvents(events.filter(x => x.hasAccess));
      console.log(events);
      setIsFetching(false);
    }).catch(error => {
      console.warn("Error getting user events", error.response?.data?.message || error);
      setIsFetching(false);
    });
  }, [userId]);

  const handleAdd = async () => {
    if (selectedEvent === 0) return;
    try {
      setIsFetching(true);
      await api.addUserToEvent(selectedEvent, userId, { sendEventEmail });
      getUserEvents();
    }
    catch (error) {
      console.error("Error adding user to event", error.response?.data?.message || error)
      setIsFetching(false);
      feedback.snackbar({ text: error.response?.data?.message || "Error removing user", type: "error" });
      return;
    }
    setSendEventEmail(false);
    setSelectedEvent(0);
    feedback.snackbar({ text: "Access granted", type: "success" });
  }

  const handleDelete = eventId => async () => {
    if (!await feedback.confirm({ title: "Remove access to this event?" }))
      return;
    try {
      setIsFetching(true);
      await api.removeUserFromEvent(eventId, userId);
      getUserEvents();
    }
    catch (error) {
      console.error("Error removing access", error.response?.data?.message || error)
      setIsFetching(false);
      feedback.snackbar({ text: error.response?.data?.message || "Error adding user", type: "error" });
      return;
    }
    feedback.snackbar({ text: "Access removed", type: "success" });
  }

  useEffect(() => {
    getUserEvents();
  }, [getUserEvents]);

  const availableEvents = allEvents.filter(x => !events.map(c => c.id).includes(x.id));

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {isFetching ? <LinearProgress variant="query" /> : <div style={{ height: 4 }}></div>}
      <DialogTitle id="alert-dialog-title">{"User's events"}</DialogTitle>
      <DialogContent>
        {availableEvents.length > 0 &&
          <>
            <Select
              style={{ minWidth: 150 }}
              labelId="event-label"
              id="event-select"
              value={selectedEvent}
              onChange={e => setSelectedEvent(e.target.value)}
            >
              <MenuItem value={0}>Select event</MenuItem>
              {availableEvents.map(event => (<MenuItem value={event.id} key={event.id}>{event.name}</MenuItem>))}
            </Select>
            <FormControlLabel
              style={{ marginLeft: 5 }}
              control={
                <Checkbox
                  checked={sendEventEmail}
                  onChange={e => setSendEventEmail(e.target.checked)}
                  color="primary"
                />
              }
              label={"Send Email"}
            />
            <Button color="primary" disabled={selectedEvent === 0} onClick={handleAdd}>Add</Button>
            <br />
            <br />
          </>
        }
        {events.length > 0 ?
          <List component="nav">
            {events.map((event, i) => (
              <ListItem key={event.id}>
                <ListItemText primary={event.name} />
                <IconButton onClick={handleDelete(event.id)}>
                  <RemoveIcon />
                </IconButton>
              </ListItem>
            ))}
          </List>
          :
          <DialogContentText>
            No events available for this user.
          </DialogContentText>
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default inject(({ store }, props) => ({
  allEvents: store.events,
}))(UserEventsDialog);