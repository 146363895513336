import React from 'react';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Link } from 'react-router-dom';
import usePortal from './hooks/usePortal';
import { formatDateInterval, getUpcomingEvents } from '../utils';

const useStyles = makeStyles((theme) => ({
  image: {
  },
  bar: {
    height: 40,
  },
  tile: {
    "& img": {
      transform: 'scale(1)',
      transition: 'transform .5s ease',
      left: 0,
      top: 0,
      maxWidth: "100%",
    },
    '&:hover': {
      '& img': {
        transform: 'scale(1.06)',
      }
    }
  },
}));

const EventsGrid = ({ events }) => {
  const { urlPrefix } = usePortal();
  const classes = useStyles();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <ImageList rowHeight={230} cols={isSm ? 1 : 3}>
        {getUpcomingEvents(events).map((event) => (
          <ImageListItem key={event.id} component={Link} to={urlPrefix + "/event/" + event.alias} className={classes.tile} >
            <img src={event.imageUrl} alt={event.name} className={classes.image} />
            <ImageListItemBar
              // title={event.name}
              // subtitle={moment(event.startDate).format("LL")}
              title={formatDateInterval(event.startDate, event.endDate)}
              className={classes.bar}
            />
          </ImageListItem>
        ))}
      </ImageList>
    </>
  );
}

export default EventsGrid;
