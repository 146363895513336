import zulipInit from 'zulip-js';
import config from '../../config';
import { observable, action, computed, /* reaction,  */runInAction, makeObservable } from "mobx";
import { wrapObject } from './SdkWrapper';
import moment from 'moment-timezone';

const getLogger = name => ({
  log: (...x) => {
    console.log(name, ...x);
  },
  warn: (...x) => {
    console.warn(name, ...x);
  },
  error: (...x) => {
    console.error(name, ...x);
  },
});

const ROLES_LIST = {
  100: { name: "Administrator", label: "Administrator" },
  200: { name: "Administrator", label: "Moderator" },
  300: { name: "Moderator", label: "Moderator" },
  400: { name: "Member", label: "" },
  600: { name: "Guest", label: "banned" },
};

const ADMIN_ROLES = [100, 200, 300];

class ChatConnection {
  @observable client;
  @observable roomId;
  @observable state = "Stopped";
  @observable profile = {};
  @observable users = [];
  // @observable usersCount = 0;
  // @observable onlineCount = 0;
  @observable groupUsers = [];
  @observable presences = {};

  constructor({ username, password }) {
    this.config = { username, password, realm: config.chatRealm };
    this.logger = getLogger("[CHAT CONNECTION]");
    this.users = [];
    this.presences = {};
    makeObservable(this);
  }

  @action.bound
  dispose() {
    this.state = "Stopped";
    clearInterval(this.presenceInterval);
    clearTimeout(this.usersTimeout);
    this.stopped = true;
    window.removeEventListener("focus", this.postPresence, false);
    window.removeEventListener("blur", this.postPresence, false);
  }

  @action.bound
  async init(roomId, onMessage, onDeleteMessage, onUpdateMessageFlags) {
    this.state = "Connecting";
    this.roomId = roomId;
    this.logger.log("starting");
    try {
      this.client = await zulipInit(this.config);
      if (!this.client.config.apiKey)
        throw new Error("invalid credentials");
      wrapObject(this.client, "wrapperError");
      this.logger.log("started", this.client);
    } catch (error) {
      this.logger.error("init chat error", error);
      runInAction(() => this.state = "Error");
      // setTimeout(() => this.init(roomId, onMessage, onDeleteMessage), 1000);
      return;
    }

    this.client.users.ban = id => this.client.callEndpoint("/users/" + id, "PATCH", { role: 600 });
    this.client.users.postPresence = status => this.client.callEndpoint("/users/me/presence", "POST", { status });

    try {
      const profile = await this.client.users.me.getProfile();
      this.logger.log("got profile", profile);
      runInAction(() => this.profile = profile);
    } catch (error) {
      this.logger.error("get profile error", error);
      runInAction(() => this.state = "Error");
      // setTimeout(() => this.init(roomId, onMessage, onDeleteMessage), 1000);
      return;
    }

    this.getUsers();

    if (this.roomId)
      await this.joinGroup();
    await this.register(onMessage, onDeleteMessage, onUpdateMessageFlags);
    runInAction(() => this.state = "Connected");
    const messages = await this.getMessages();
    if (!messages) {
      runInAction(() => this.state = "Error");
    }

    this.postPresence();
    this.presenceInterval = setInterval(() => this.postPresence(), 50_000);
    window.addEventListener("focus", this.postPresence, false);
    window.addEventListener("blur", this.postPresence, false);

    // const params = {
    //   email: "ssp@slidespiel.com",
    //   password: "MD5bKQmWrPFqRQtz",
    //   full_name: "System",
    // };
    // const params = {
    //   email: "chat-guest@slidespiel.com",
    //   password: "chat-guest",
    //   full_name: "Guest",
    // };
    // const newUser = await this.client.users.create(params);
    // this.logger.log("newUser", newUser);
    // this.logger.log("newUser update", await this.client.callEndpoint("/users/" + "20", "PATCH", { role: 600 }));
    // this.logger.log("newUser get", await this.client.callEndpoint("/users/" + "test1@test.com"));
    // this.logger.log("change email", await this.client.callEndpoint("/settings", "PATCH", { email: "chat-owner@slidespiel.com" }));

    return messages;
  }

  postPresence = async () => {
    try {
      const { presences: data } = await this.client.users.postPresence(document.hasFocus() ? "active" : "idle");
      const presences = { ...data };
      for (let item in presences) {
        // this.logger.log(item);
        presences[item] = presences[item].aggregated;
      }
      runInAction(() =>this.presences = presences);
      this.calcGroupUsers();
      // this.logger.log("presences", presences, document.hasFocus());
    } catch (error) {
      this.logger.warn("Post presence error", error.message || error);
    }
  }

  async getUsers(runInTimeout = true) {
    // if (!this.roomId)
    //   return;
    try {
      const { members: users } = await this.client.users.retrieve();
      runInAction(() => this.users = users);
      // this.logger.log("got users", users);
      if (this.roomId) {
        const { subscriptions } = await this.client.streams.subscriptions.retrieve({ include_subscribers: true });
        const subscription = subscriptions.find(x => x.name === this.roomId);
        // this.logger.log("subscription", subscription, subscriptions, this.roomId);
        const { subscribers } = subscription;
        // this.logger.log('got subscribers', subscribers);
        this.subscribers = subscribers;

        // runInAction(() => this.users = users);
        this.calcGroupUsers();
      }
      if (runInTimeout)
        this.usersTimeout = setTimeout(() => this.getUsers(), 30_000);
    } catch (error) {
      this.logger.warn("get users error", error.message || error);
      this.usersTimeout = setTimeout(() => this.getUsers(), 10000);
      return;
    }
  }

  async getUserIdByName(name) {
    let user = this.users.find(x => x.full_name === name);
    if (!user) {
      await this.getUsers(false);
      user = this.users.find(x => x.full_name === name);
      if (!user)
        return "";
    }
    return user.user_id;
  }

  async getUserIdByEmail(email) {
    let user = this.users.find(x => x.delivery_email === email);
    if (!user) {
      await this.getUsers(false);
      user = this.users.find(x => x.delivery_email === email);
      if (!user)
        return "";
    }
    return user.user_id;
  }

  @action.bound
  calcGroupUsers() {
    if (!this.roomId)
      return;
    this.groupUsers = this.users.filter(x => this.subscribers.includes(x.email) && x.full_name !== "System" && x.full_name !== "Guest");
    for (let user of this.groupUsers) {
      const presence = this.presences[user.email];
      // this.logger.log("presences", email, presence);
      if (!presence)
        continue;
      const { status, timestamp } = presence;
      // this.logger.log("count", email, status, Date.now() / 1000 - timestamp );
      if (Date.now() / 1000 - timestamp > 60 * 5)
        continue;
      if (status === "active")
        user.online = true;
    }
  }

  async joinGroup() {
    try {
      const { subscriptions } = await this.client.streams.subscriptions.retrieve();
      this.logger.log("got subscriptions", subscriptions);
      if (subscriptions.map(x => x.name).includes(this.roomId)) {
        this.logger.log("user is already in group");
        // return;
      }
      else
        await this.client.users.me.subscriptions.add({ subscriptions: [{ name: this.roomId }] });
    } catch (error) {
      this.logger.error("join error", error);
      setTimeout(() => this.joinGroup, 1000);
      return;
    }
  }

  async register(onMessage, onDeleteMessage, onUpdateMessageFlags) {
    if (!onMessage || !onDeleteMessage)
      return this.logger.error("wrong register call, check params");
    const params = {
      event_types: ["message", "delete_message", "presence", "update_message_flags"],
      slim_presence: true,
      narrow: this.roomId ? [["stream", this.roomId]] : [["is", "private"]],
    };

    try {
      const { queue_id } = await this.client.queues.register(params);
      this.queue_id = queue_id;
    } catch (error) {
      this.logger.error("Register error", error);
      setTimeout(() => this.register(onMessage, onDeleteMessage, onUpdateMessageFlags), 1000);
      return;
    }

    this.logger.log("got queue", this.queue_id);

    const fetchEvents = async (eventId) => {
      // if (this.state !== "Connected")
      //   return this.logger.warn("fetch error", "not connected");
      const eventParams = {
        queue_id: this.queue_id,
        last_event_id: eventId === undefined ? -1 : eventId,
        dont_block: false,
        slim_presence: true
      };
      if (this.stopped)
        return;
      try {
        const { events } = await this.client.events.retrieve(eventParams);
        if (this.stopped)
          return;
        this.logger.log("new events", events);
        if (events.some(x => x.type === "message"))
          onMessage(events.filter(x => x.type === "message"));
        if (events.some(x => x.type === "delete_message"))
          onDeleteMessage(events.filter(x => x.type === "delete_message"));
        if (events.some(x => x.type === "update_message_flags"))
          onUpdateMessageFlags(events.filter(x => x.type === "update_message_flags"));
        if (events.some(x => x.type === "presence")) {
          for (let event of events.filter(x => x.type === "presence")) {
            this.presences[event.email] = event.presence[Object.keys(event.presence)[0]]
            // this.logger.log("new presence", event.presence[Object.keys(event.presence)[0]]);
          }
          this.calcGroupUsers();
        }
        const newEventId = events[events.length - 1].id;
        fetchEvents(newEventId);

      } catch (error) {
        if (error.message.includes("queue id")) {
          this.register(onMessage, onDeleteMessage);
          return;
        }
        this.logger.warn("events retrieve error", error.message || error);
        setTimeout(() => fetchEvents(eventId), 10000);
      }
    }
    fetchEvents();
  }

  send(params) {
    return this.client.messages.send({ ...params, queue_id: this.queue_id, });
  }

  async getMessages(prevMessages = [], anchor) {
    if (!this.state === "Connected") {
      this.logger.error("Can't get messages: not connected");
      return;
    }
    const readParams = {
      anchor: anchor || "newest",
      num_before: 100,
      num_after: 0,
      narrow: this.roomId ? [{ operator: "stream", operand: this.roomId }] : [{ operator: "is", operand: "private" }],
      apply_markdown: false,
      slim_presence: true,
    };
    try {
      const { messages, found_oldest } = await this.client.messages.retrieve(readParams);
      let result = [...messages, ...prevMessages];
      if (found_oldest)
        return result;
      else
        return this.getMessages(result, messages[0].id)
    } catch (error) {
      this.logger.error("Get messages error", error);
      return;
    }
  }

  async getUserRoleLabel(id) {
    let user = this.users.find(x => x.user_id === id);
    if (!user) {
      await this.getUsers(false);
      user = this.users.find(x => x.user_id === id);
      if (!user)
        return "";
    }
    return ROLES_LIST[user.role].label;
  }

  getUserName(id) {
    let user = this.users.find(x => x.user_id === id);
    if (!user) {
      return "";
    }
    // console.log(user);
    return user.full_name;
  }

  getUserPresence(id) {
    let user = this.users.find(x => x.user_id === id);
    if (!user) {
      return "";
    }
    const presence = this.presences[user.email];
    this.logger.log("getUserPresence", this.presences, presence);
    if (!presence)
      return "";
    const { status, timestamp } = presence;
    if (Date.now() / 1000 - timestamp > 60 * 5)
      return "last seen " + moment(timestamp * 1000).fromNow();
    if (status === "active")
      return "online"
    else
      return "last seen " + moment(timestamp * 1000).fromNow();
  }

  @computed
  get isAdmin() {
    if (!this.profile)
      return false;
    // const adminFields = ["is_admin", "is_owner"];
    // return false;
    return ADMIN_ROLES.includes(this.profile.role);
  }

  @computed
  get isBanned() {
    if (!this.profile)
      return false;
    return this.profile.role === 600;
  }

  @computed
  get usersCount() {
    return this.groupUsers.length;
  }

  @computed
  get onlineCount() {
    return this.groupUsers.filter(x => x.online).length;
  }
}

export default ChatConnection;