
import React, { useState, useMemo, useEffect } from 'react';
import { inject } from 'mobx-react';
import { Grid } from '@material-ui/core';
import BasicUserFields from '../BasicUserFields';
import CustomFormFields from '../CustomFormFields';
import useFormMetaData from '../hooks/useFormMetaData';
import PromoCode from '../PromoCode';
import useProductPrice from '../hooks/useProductPrice';
import { injectStripe, Elements } from 'react-stripe-elements';
import CardComponent from '../CardComponent';
import LoadingButton from '../LoadingButton';
import { validateCustomFields } from '../../utils';
import { useFeedback } from '../feedback/Service';
import usePortal from '../hooks/usePortal';
import api from '../../api';
import AlreadyHaveAccountLink from '../AlreadyHaveAccountLink';
import config from '../../uiConfig';
import PrivacyPolicyCheckbox from '../PrivacyPolicyCheckbox';

const CombinedBuyForm = ({ user, portal, product, onSuccess, store, stripe, setSize }) => {
  const [isPosting, setIsPosting] = useState(false);
  const [privacyAccepted, setPrivacyAccepted] = useState(false);
  const hasPromoCode = useMemo(() => product?.pricingReasons?.map(x => x.toLowerCase)?.includes("promocode"), [product]);

  const userData = useMemo(() => {
    if (!user?.signupFormData)
      return [];
    // console.log("user?.signupFormData", JSON.parse(user.signupFormData));
    return JSON.parse(user.signupFormData);
  }, [user]);

  const userFields = useMemo(() => Object.keys(userData), [userData]);

  const {
    metaData: portalFormMetaData,
    state: portalState,
    onChange: onPortalStateChange,
    setState: setPortalState,
    setErrors: setPortalErrors,
    errors: portalErrors
  } = useFormMetaData({
    metaDataJson: portal.signUpFormMetaData,
    hasBasicUserFields: true,
    fieldsToExclude: userFields
  });

  const {
    metaData: productFormMetaData,
    state: productState,
    onChange: onProductStateChange,
    setState: setProductState,
    setErrors: setProductErrors,
    errors: productErrors
  } = useFormMetaData({ metaDataJson: product?.signUpFormMetaData, hasPromoCode, fieldsToExclude: [...(portalFormMetaData?.fields?.map(x => x.id) || []), ...userFields] });

  const combinedState = useMemo(() => {
    let data = { ...portalState, ...productState };
    if (user)
      data.email = user.email;
    if (userData) 
      data = { ...userData, ...data };
    return data;
  }, [user, portalState, productState, userData]);

  const [price, setPrice, isPriceLoading] = useProductPrice({ product, state: combinedState });
  const feedback = useFeedback();
  const isLoading = isPriceLoading || isPosting;
  const { portalId, portalName } = usePortal();

  const handleSubmit = async e => {
    e.preventDefault();
    if (!validateCustomFields(portalFormMetaData, portalState, setPortalErrors))
      return;
    if (!validateCustomFields(productFormMetaData, productState, setProductErrors))
      return;
    if (portalErrors.email)
      return;
    setIsPosting(true);
    let tokenId;
    if (price) {
      const { token, error } = await stripe.createToken({ type: 'card' });
      if (error) {
        console.warn("Payment error", error);
        feedback.snackbar({ text: error.message, type: "error" });
        setIsPosting(false);
        return;
      }
      if (!token?.id) {
        console.warn("Empty token", token);
        feedback.snackbar({ text: "Unknown payment error", type: "error" });
        setIsPosting(false);
        return;
      }
      tokenId = token.id;
    }

    if (!user) {
      const { firstName, lastName, email, phone, password } = portalState;
      let formData = {
        firstName,
        lastName,
        email,
        phone,
        portalId,
        signUpFormData: JSON.stringify({ ...portalState, password: undefined }),
      };
      if (password)
        formData.password = password;
      try {
        const data = await api.register(formData);
        console.log(data);
        localStorage.setItem(`${portalName}-access_token`, data.accessToken);
        const user = await api.getUser("me", data.accessToken);
        console.log("register completed", user);
        if (!product || (product.id === "recording" && price === 0)) {
          setIsPosting(false);
          feedback.snackbar({ text: "Registration completed!", type: "success" });
          if (onSuccess)
            onSuccess(true);
        }
        store.setUser(user);
      }
      catch (error) {
        console.warn("Register error", error.response);
        feedback.snackbar({ text: error.response?.data?.message || "Unknown error", type: "error" });
        setIsPosting(false);
        return;
      }
    }

    if (product) {
      if (product.id === "recording") {
        try {
          if (price === 0)
            return;
          const data = await api.buyRecording(product.recording.id, tokenId, portalName);
          console.log(data);
          // feedback.snackbar({ text: "Purchase completed!", type: "success" });
          setIsPosting(false);
          if (onSuccess) {
            onSuccess(data);
            // return;
          }
        }
        catch (error) {
          console.warn("Buy recording error", error.response);
          feedback.snackbar({ text: error.response?.data?.message || "Unknown error", type: "error" });
          setIsPosting(false);
          return;
        }
      } else {
        if (product.isFree) {
          productState.ext_p = product.id;
        }
        const formData = {
          token: tokenId,
          promoCode: productState.promoCode,
          productId: product.isFree ? undefined : product.id,
          // portalId,
          signUpFormData: JSON.stringify({ ...portalState, ...productState }),
          // exhibitHallOnly,
          // pricing: getPricingData(product.pricingReasons, productState),
        };
        try {
          const data = await api.buyProduct(formData);
          await Promise.all([store.fetchEvents(), store.fetchTracks()]);
          console.log(data);
          // feedback.snackbar({ text: "Purchase completed!", type: "success" });
          setIsPosting(false);
          if (onSuccess) {
            onSuccess("all");
            // return;
          }
        }
        catch (error) {
          console.warn("Buy product error", error.response);
          feedback.snackbar({ text: error.response?.data?.message || "Unknown error", type: "error" });
          setIsPosting(false);
          return;
        }
      }
      feedback.snackbar({ text: "Purchase completed!", type: "success" });
    }
    // history.push(signInRedirect);
  }

  useEffect(() => {
    if (portalFormMetaData?.size === "md" || productFormMetaData?.size === "md")
      setSize("md");
    else
      setSize("sm");
  }, [portalFormMetaData, productFormMetaData])

  // console.log(portalFormMetaData, productFormMetaData, { ...product });
  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={1}>
        {!user &&
          <>
            <BasicUserFields state={portalState} onChange={onPortalStateChange} size={portalFormMetaData?.size} errors={portalErrors} setErrors={setPortalErrors} />
            <CustomFormFields metaData={portalFormMetaData} state={portalState} errors={portalErrors} setState={setPortalState} setErrors={setPortalErrors} />
          </>
        }
        <CustomFormFields metaData={productFormMetaData} state={productState} errors={productErrors} setState={setProductState} setErrors={setProductErrors} />
        {hasPromoCode &&
          <Grid item xs={12} sm={6} md={productFormMetaData?.size === "sm" ? 6 : 4}>
            <PromoCode setPrice={setPrice} handleChange={onProductStateChange} value={productState.promoCode} eventId={product.id} />
          </Grid>
        }
        {config.acceptPrivacyPolicyText &&
          <Grid item xs={12} >
            <PrivacyPolicyCheckbox checked={privacyAccepted} handleChange={setPrivacyAccepted} />
          </Grid>
        }
        {!!price &&
          <Grid item xs={12}>
            <br />
            <CardComponent />
          </Grid>
        }
        <Grid item xs={12}>
          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            loading={isLoading}
            disabled={config.acceptPrivacyPolicyText && !privacyAccepted}
          >
            {price ? "Pay $" + price : "Submit"}
          </LoadingButton>
        </Grid>
      </Grid>
      {!user && <AlreadyHaveAccountLink />}
    </form >
  );
}

const InjectedCompinedBuyForm = inject(({ store }, props) => ({
  isLoggedIn: store.isLoggedIn, events: store.events, products: store.products, portal: store.portal, store, user: store.currentUser
}))(CombinedBuyForm);

const StripeInjectedForm = injectStripe(InjectedCompinedBuyForm);

const StripeForm = props => (
  <Elements>
    <StripeInjectedForm {...props} />
  </Elements>
)

export default StripeForm